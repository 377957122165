body {
  margin: 1rem;
  font-family: 'Neutra Text Alt', sans-serif;
  font-family: 'Neutra Text Light', sans-serif;
  font-family: 'Neutra Text TF', sans-serif;
  font-family: 'Neutra Text', sans-serif, "montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  overflow-x: hidden;
  font-family: "Neutra Text",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* *{
  outline: 1px solid red;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
