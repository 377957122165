a {
    color: inherit;
    background-image: linear-gradient(
      to right,
      #280704,
      #190503 50%,
      #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  
  a:before {
    content: '';
    background: #190503;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  a:hover {
   background-position: 0;
  }
  
  a:hover::before {
    width:100%;
  }

  .logoA {
    text-decoration: none;
    background-image: none;
    background-size: 1;
    background-position: initial;
    display: inline-block;
    padding: 5px 0;
    position: initial;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  .logoA:before {
    content: '';
    background: none;
    display: none;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }

  .logo {
    height: 30pc;
    background-color: red;
  -webkit-mask: url("../assets/images/732.svg") no-repeat center;
  mask: url("../assets/images/732.svg") no-repeat center;
  }